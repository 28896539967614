/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./perk-details.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../vertical-nav/vertical-nav.component.ngfactory";
import * as i3 from "../vertical-nav/vertical-nav.component";
import * as i4 from "@angular/router";
import * as i5 from "../../services/api.service";
import * as i6 from "../../services/mixpanel.service";
import * as i7 from "@ng-bootstrap/ng-bootstrap";
import * as i8 from "../menu-bar/menu-bar.component.ngfactory";
import * as i9 from "../menu-bar/menu-bar.component";
import * as i10 from "@angular/common";
import * as i11 from "../static-footer/static-footer.component.ngfactory";
import * as i12 from "../static-footer/static-footer.component";
import * as i13 from "./perk-details.component";
var styles_PerkDetailsComponent = [i0.styles];
var RenderType_PerkDetailsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PerkDetailsComponent, data: {} });
export { RenderType_PerkDetailsComponent as RenderType_PerkDetailsComponent };
function View_PerkDetailsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 27, "div", [["class", "content-wrapper mb-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 26, "div", [["class", "row pl-3 pl-md-3 pl-lg-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 25, "div", [["class", "container-fluid full-width-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 24, "div", [["class", "row perk-details-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "button", [["class", "back-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goBack() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u2190 "])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "col-12 col-md-6 perk-image"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "img", [], [[8, "src", 4], [8, "alt", 0]], null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 18, "div", [["class", "col-12 col-md-6 perk-details"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", ""])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "p", [["class", "discount-full"]], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, ["", ""])), (_l()(), i1.ɵeld(14, 0, null, null, 1, "p", [["class", "main-copy"]], null, null, null, null, null)), (_l()(), i1.ɵted(15, null, ["", ""])), (_l()(), i1.ɵeld(16, 0, null, null, 4, "div", [["class", "discount-code"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Discount Code: "])), (_l()(), i1.ɵeld(19, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(20, null, ["", ""])), (_l()(), i1.ɵeld(21, 0, null, null, 2, "a", [["rel", "noreferrer noopener"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 1, "button", [["class", "use-perk-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickedPerkButton() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["USE THE PERK \u2192 "])), (_l()(), i1.ɵeld(24, 0, null, null, 3, "div", [["class", "support-box"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" If you have any problems redeeming your perk, please contact our support team and they\u2019ll help you rectify the issue. "])), (_l()(), i1.ɵeld(26, 0, null, null, 1, "a", [["href", "#"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Contact Support here."]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getPerkImage(); var currVal_1 = ((_co.perkDetails == null) ? null : _co.perkDetails.name); _ck(_v, 8, 0, currVal_0, currVal_1); var currVal_2 = ((_co.perkDetails == null) ? null : _co.perkDetails.name); _ck(_v, 11, 0, currVal_2); var currVal_3 = ((_co.perkDetails == null) ? null : _co.perkDetails.discountCopyFull); _ck(_v, 13, 0, currVal_3); var currVal_4 = ((_co.perkDetails == null) ? null : _co.perkDetails.mainCopy); _ck(_v, 15, 0, currVal_4); var currVal_5 = ((_co.perkDetails == null) ? null : _co.perkDetails.discountCode); _ck(_v, 20, 0, currVal_5); var currVal_6 = ((_co.perkDetails == null) ? null : _co.perkDetails.link); _ck(_v, 21, 0, currVal_6); }); }
function View_PerkDetailsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "loading-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Loading..."]))], null, null); }
export function View_PerkDetailsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "vertical-nav", [], null, null, null, i2.View_VerticalNavComponent_0, i2.RenderType_VerticalNavComponent)), i1.ɵdid(1, 245760, null, 0, i3.VerticalNavComponent, [i4.Router, i5.Api, i6.MixpanelService, i7.NgbModal], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "content-area"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "menu-bar", [], null, null, null, i8.View_MenuBarComponent_0, i8.RenderType_MenuBarComponent)), i1.ɵdid(4, 114688, null, 0, i9.MenuBarComponent, [i4.Router, i5.Api, i7.NgbModal], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PerkDetailsComponent_1)), i1.ɵdid(6, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "static-footer", [], null, null, null, i11.View_StaticFooterComponent_0, i11.RenderType_StaticFooterComponent)), i1.ɵdid(8, 114688, null, 0, i12.StaticFooterComponent, [i5.Api, i4.ActivatedRoute], null, null), (_l()(), i1.ɵand(0, [["loading", 2]], null, 0, null, View_PerkDetailsComponent_2))], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); _ck(_v, 4, 0); var currVal_0 = _co.perkDetails; var currVal_1 = i1.ɵnov(_v, 9); _ck(_v, 6, 0, currVal_0, currVal_1); _ck(_v, 8, 0); }, null); }
export function View_PerkDetailsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-perk-details", [], null, null, null, View_PerkDetailsComponent_0, RenderType_PerkDetailsComponent)), i1.ɵdid(1, 114688, null, 0, i13.PerkDetailsComponent, [i4.ActivatedRoute, i5.Api, i10.Location, i6.MixpanelService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PerkDetailsComponentNgFactory = i1.ɵccf("app-perk-details", i13.PerkDetailsComponent, View_PerkDetailsComponent_Host_0, {}, {}, []);
export { PerkDetailsComponentNgFactory as PerkDetailsComponentNgFactory };

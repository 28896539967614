<div class="mobile-nav d-xl-none" id="sidebar">
  <div class="btn" (click)="toggle()">
    <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="bars" class="svg-inline--fa fa-bars fa-w-14"
      role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" style="width: 20px;">
      <path fill="currentColor"
        d="M442 114H6a6 6 0 0 1-6-6V84a6 6 0 0 1 6-6h436a6 6 0 0 1 6 6v24a6 6 0 0 1-6 6zm0 160H6a6 6 0 0 1-6-6v-24a6 6 0 0 1 6-6h436a6 6 0 0 1 6 6v24a6 6 0 0 1-6 6zm0 160H6a6 6 0 0 1-6-6v-24a6 6 0 0 1 6-6h436a6 6 0 0 1 6 6v24a6 6 0 0 1-6 6z">
      </path>
    </svg>
  </div>
</div>
<div class="vertical-nav bg-white" [@popOverState]="stateName" id="sidebar">
  <div class="text-center">
    <a routerLink="/dashboard"><img class="main-logo logo-container" [src]="getNavLogo()" [class.super-logo]="user.partnerId === 'super'"></a>
  </div>
  <ul class="nav flex-column bg-white p-2">
    <li class="nav-item">
      <div [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}" class="box-opener" [class.expanded]="showFlights" (click)="showFlights = !showFlights">
        <a class="nav-link" [class.active]="isActive('/dashboard') || isActive('/dashboard?dealType=0') || isActive('/dashboard?dealType=1') || isActive('/profile/departure') ">
          <i class="fa fa-ticket mr-3 mt-3 fa-fw"></i>Flights
          <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="chevron-down"
            class="svg-inline--fa fa-chevron-down fa-w-14 ml-2" role="img" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512" style="width: 15px;">
            <path fill="currentColor"
              d="M443.5 162.6l-7.1-7.1c-4.7-4.7-12.3-4.7-17 0L224 351 28.5 155.5c-4.7-4.7-12.3-4.7-17 0l-7.1 7.1c-4.7 4.7-4.7 12.3 0 17l211 211.1c4.7 4.7 12.3 4.7 17 0l211-211.1c4.8-4.7 4.8-12.3.1-17z">
            </path>
          </svg>
        </a>
      </div>
      <div class="box pl-1" [class.opened]="showFlights">
        <a [routerLink]="['/dashboard']" [queryParams]="{dealType: 0}" [routerLinkActive]="['active']" [class.active]="isActive('/dashboard')" class="nav-link pt-2">Deals</a>
        <a [routerLink]="['/profile/departure']" [routerLinkActive]="['active']" class="nav-link pt-2" (click)="navTracking('Flight Preferences')">Flight Preferences</a>
      </div>
    </li>
    <li class="nav-item">
      <a [routerLink]="['/perks']" [routerLinkActive]="['active']" class="nav-link" (click)="navTracking('Partner Perks')">
        <i class="fa fa-money mr-3 mt-3 fa-fw"></i>Member Perks
      </a>
    </li>
    <li class="nav-item">
      <a href="https://dollarflightclub.com/navigator-newsletter/" target="_blank" class="nav-link" (click)="navTracking('Newsletter')">
        <i class="fa fa-book mr-3 mt-3 fa-fw"></i>Newsletter
      </a>
    </li>
    <li class="nav-item">
      <a [routerLink]="['/support']" [routerLinkActive]="['active']" class="nav-link" (click)="navTracking('Support')">
        <i class="fa fa-question-circle mr-3 mt-3 fa-fw"></i>Support
      </a>
    </li>
    <li class="nav-item">
      <a [routerLink]="['/profile/account']" [routerLinkActive]="['active']" class="nav-link" (click)="navTracking('Account')">
        <i class="fa fa-user mr-3 mt-3 fa-fw"></i>Account
      </a>
    </li>
  </ul>
  <div class="upgrade-container text-center mx-2 mx-md-3"
      *ngIf="api.user?.membershipId === this.memberships.free">
      <div>
        <p *ngIf="api.user?.membershipId === this.memberships.free">Upgrade to Premium to get all our best deals and
          features</p>
        <p *ngIf="api.user?.membershipId != this.memberships.free">Upgrade to Premium <b class="text-white">PLUS+</b> for
          First and Business Class flights.</p>
        <button (click)="upgrade()" class="btn w-50 rounded-btn mx-1 py-1 primary-btn-inverse">Upgrade</button>
      </div>
  </div>
</div>

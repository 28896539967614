<vertical-nav></vertical-nav>
<div class="content-area">
  <menu-bar></menu-bar>
  <div class="content-wrapper">
    <div class="row">
      <div *ngIf="activeSection=='departure'" class="col-12">
        <p class="font-weight-bold title">Flight Alerts</p>
        <!--DEPARTURE AIRPORTS-->
        <div *ngIf="activeSection=='departure'"
             class="col-lg-12 col-md-12 col-12 animated fadeIn settings-card card-shadow px-md-5 px-4">
          <h3 class="mt-20 bold ml-1">Departure Airports</h3>
          <p class="mt-4 bold ml-1 pl-1">
            <span *ngIf="this.api.premium">Select up to 4 departure airports</span>
            <span *ngIf="!this.api.premium">Select a departure airport</span>
          </p>
          <hr class="ml-1">
          <form [formGroup]="favoritesSelectionForm" autocomplete="off" novalidate>
            <div class="col-lg-12 col-md-12 col-12 px-0">
              <div class="row pl-2">
                <div *ngIf="loadingDepartures" class="col-12 my-5 text-center pl-0 pr-3">
                  <img src="../../../assets/svgs/puff-loader.svg" style="width: 70px;"/>
                </div>
                <div *ngIf="((api.premium && departures.length < 4 )|| departures.length==0) && !loadingDepartures"
                     class="col-xl-2 col-md-2 mt-4 pr-4 mr-1 pl-2 min-w-fit">
                  <div class="pl-1">My Departure Airports</div>
                </div>
                <div *ngIf="((api?.premium && departures.length < 4 )|| departures.length==0) && !loadingDepartures"
                     class="col-xl-6 col-md-6 mt-3 pl-sm-0 pl-2 n-ml-20">
                  <div class="form-group d-flex">
                    <autocompleter (selected)="departureSelected($event)" [loadingText]="api.strings?.portal.searching"
                                   [noResultsText]="api.strings?.portal.no_airports_found"
                                   [placeholder]="api.strings?.portal.select_origin"
                                   [results]="5"
                                   [searchPlaceholder]="api.strings?.portal.airports_search"
                                   [url]="api.config?.airportsSearchUrl" class="w-100" formControlName="departure">
                    </autocompleter>
                  </div>
                </div>
                <div *ngIf="departure && !loadingDepartures" class="offset-lg-1 col-xl-2 col-md-2 mt-md-3">
                  <button (click)="addDeparture()" class="btn btn-default"><i class="fa fa-check"></i>
                    {{ api.strings?.portal.add }}
                  </button>
                </div>
              </div>
              <div *ngIf="!loadingDepartures&&departures.length==0" class="no-results pl-2">
                <i class="icon_drawer_alt"></i>
                <div class="clear-20"></div>
                {{ api.strings?.portal.no_departures }}
              </div>
              <div *ngIf="!loadingDepartures" class="row pl-1">
                <div *ngFor="let departure of departures" class="col-lg-3 col-md-4 col-sm-6 col-12 margin-bottom10px">
                  <div class="imgdeal large pl-0">
                    <a (click)="removeDeparture(departure)" class="delete" href="javascript:void(0)"><i
                      class="fa fa-trash"></i></a>
                    <img class="departure-card" src="{{departure.image?departure.image:'/assets/images/airport.jpg'}}"/>
                    <p class="departure-name">{{ departure.name }}</p>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <!--NOTIFICATION SETTINGS-->
        <div class="col-lg-12 col-md-12 col-12 animated fadeIn settings-card card-shadow px-40">
          <h3 class="ml-10 mt-20 bold">Notification Settings</h3>
          <ul class="profile">
            <li (click)="activeSubsection='email-notifications';"
                [ngClass]="{'active':activeSubsection=='email-notifications'}">
              <div class="row justify-content-between">
                <div class="col-lg-4 col-md-4 col-12">
                  <label class="main-label">Email Notifications:</label>
                </div>

                <div class="col-lg-4 col-md-4 col-12 text-right">
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="checkbox" role="switch" id="emailNotificationCheck" checked [formControl]="emailNotificationControl">
                    <label class="form-check-label" for="emailNotificationCheck">{{ emailNotificationControl.value ? 'Enabled': 'Disabled' }}</label>
                  </div>
                </div>

              </div>
            </li>
            <li (click)="activeSubsection='sms-notifications';"
                *ngIf="displayPhone"
                [ngClass]="{'active':activeSubsection=='sms-notifications'}">
              <div class="row">
                <div class="col-lg-4 col-md-4 col-12">
                  <label class="main-label">SMS Notifications:</label>
                </div>
                <div class="col-lg-4 col-md-4 col-12">
                  <p class="pl-xs-3">To opt-out of SMS alerts, text 'STOP' to (425) 517-1791 or 'START' to
                    resubscribe!</p>

                </div>
                <div class="col-lg-4 col-md-4 col-12 text-left">
                  <p class="pl-xs-3"> Don't forget to <a [routerLinkActive]="['active']"
                                                         [routerLink]="['/profile/account']">update</a> your phone number!
                  </p>
                  <a (click)="stopEvent($event);activeSubsection=''" *ngIf="activeSubsection=='sms-notifications'" class="close"
                     href="javascript:void(0);">x</a>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <form [formGroup]="favoritesSelectionForm" autocomplete="off" novalidate>
          <div class="col-lg-12 col-md-12 col-12 px-0">
            <div class="settings-card card-shadow px-md-5 px-4">
              <div class="row pl-1">
                <div class="col-md-12 mt-10">
                  <h3 class="mt-20 mb-20 bold">Dream Destinations</h3>
                  <p class="mt-4 bold ml-1">
                    <span>Select up to 10 dream destinations</span>
                  </p>
                  <hr>
                </div>
                <div *ngIf="loadingDestinations" class="col-12 my-5 text-center pl-0 pr-3">
                  <img src="../../../assets/svgs/puff-loader.svg" style="width: 70px;"/>
                </div>
                <div *ngIf="!loadingDestinations&&destinations.length<10"
                     class="col-xl-2 col-md-2 mt-4 pr-4 mr-1 pl-3 min-w-fit">
                  <div class="mr-3">My Dream Destinations</div>
                </div>
                <div *ngIf="!loadingDestinations&&destinations.length<10"
                     class="col-xl-6 col-md-6 mt-3 pl-sm-0 pl-2 n-ml-20">
                  <div class="form-group d-flex">
                    <autocompleter (selected)="destinationSelected($event)" [loadingText]="api.strings?.portal.searching"
                                   [noResultsText]="api.strings?.portal.no_destinations_found"
                                   [placeholder]="api.strings?.portal.select_destination"
                                   [results]="5"
                                   [searchPlaceholder]="api.strings?.portal.destination_search"
                                   [url]="api.config?.apiSearchDestinations" class="w-100" formControlName="destination"></autocompleter>
                  </div>
                </div>
                <div *ngIf="destination && !loadingDestinations" class="offset-lg-1 col-xl-2 col-md-2 mt-md-3">
                  <button (click)="addDestination()" class="btn btn-default"><i class="fa fa-check"></i>
                    {{ api.strings?.portal.add }}
                  </button>
                </div>
              </div>
              <div *ngIf="!loadingDestinations&&destinations.length==0" class="no-results pl-2">
                <i class="icon_drawer_alt"></i>
                <div class="clear-20"></div>
                {{ api.strings?.portal.no_destinations_selected }}
              </div>
              <div *ngIf="!loadingDestinations" class="row">
                <div *ngFor="let destination of destinations"
                     class="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 margin-bottom10px">
                  <div class="imgdeal large">
                    <a (click)="removeDestination(destination)" class="delete" href="javascript:void(0)"><i
                      class="fa fa-trash"></i></a>
                    <img class="departure-card"
                         src="{{destination.image?destination.image:'/assets/images/city.jpg'}}"/>
                    <p class="departure-name">{{ destination.name }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-12 col-md-12 col-12 px-0">
            <div class="settings-card card-shadow px-md-5 px-4 pb-5">
              <div class="row pl-1">
                <div class="col-md-12 mt-10">
                  <h3 class="mt-20 mb-20 bold">Favorite Airlines</h3>
                  <p class="mt-4 bold ml-1">
                    <span *ngIf="this.api?.premium">Select up to 3 favorite airlines</span>
                  </p>
                  <hr>
                </div>
                <div *ngIf="loadingAirlines" class="col-12 my-5 text-center pl-0 pr-3">
                  <img src="../../../assets/svgs/puff-loader.svg" style="width: 70px;"/>
                </div>
                <div *ngIf="!loadingAirlines&&airlines.length<3"
                     class="col-xl-2 col-md-2 mt-4 pr-4 mr-1 pl-3 min-w-fit">
                  <div class="mr-3">My Favorite Airlines</div>
                </div>
                <div *ngIf="!loadingAirlines&&airlines.length<3" class="col-xl-6 col-md-6 mt-3 pl-sm-0 pl-2 n-ml-20">
                  <div class="form-group d-flex">
                    <autocompleter (selected)="airlineSelected($event)" [loadingText]="api.strings?.portal.searching"
                                   [noResultsText]="api.strings?.portal.no_airlines_found"
                                   [placeholder]="api.strings?.portal.select_airline"
                                   [results]="5"
                                   [searchPlaceholder]="api.strings?.portal.airlines_search"
                                   [url]="api.config?.airlinesSearchUrl" class="w-100" formControlName="airline"></autocompleter>
                  </div>
                </div>
                <div *ngIf="airline && !loadingAirlines" class="offset-lg-1 col-xl-2 col-md-2 mt-md-3">
                  <button (click)="addAirline()" class="btn btn-default"><i class="fa fa-check"></i>
                    {{ api.strings?.portal.add }}
                  </button>
                </div>
              </div>
              <div *ngIf="!loadingAirlines&&airlines.length==0" class="no-results pl-2">
                <i class="icon_drawer_alt"></i>
                <div class="clear-20"></div>
                {{ api.strings?.portal.no_favorite_airlines }}
              </div>
              <div *ngIf="!loadingAirlines" class="row">
                <div *ngFor="let airline of airlines"
                     class="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 margin-bottom10px pl-md-3 pl-0">
                  <div class="imgdeal large">
                    <a (click)="removeAirline(airline)" class="delete" href="javascript:void(0)"><i
                      class="fa fa-trash"></i></a>
                    <img class="airline-card" src="{{airline.image?airline.image:'/assets/images/airline.jpg'}}"/>
                    <p class="airline-name">{{ airline.name }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <!--ACCOUNT INFORMATION-->
      <div *ngIf="activeSection=='account'" class="w-100 px-md-0 px-3">
        <p class="font-weight-bold title">Account Settings</p>
        <div class="col-lg-12 col-md-12 col-12 animated fadeIn settings-card card-shadow px-40">
          <div _ngcontent-c4="" class="col-md-12 mt-10 mb-10">
            <h3 class="mt-20 bold">My Account</h3>
          </div>
          <form [formGroup]="profileForm" autocomplete="off" novalidate>
            <ul class="profile pointer">
              <li (click)="profileForm.controls.name.setValue(api.user.name);activeSubsection='name';"
                  [ngClass]="{'active':activeSubsection=='name'}">
                <div class="row">
                  <div class="col-lg-4 col-md-4 col-sm-4 col-12">
                    <label class="main-label pl-xs-3">{{ api.strings?.portal.name }}:</label>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-6 col-12 mobile-margin-10 pointer">
                    <span *ngIf="activeSubsection!='name'" class="pl-xs-3">{{ api.user?.name }}</span>
                    <input *ngIf="activeSubsection=='name'" [ngClass]="{'input-error':validationActive&&!profileForm.controls.name.disabled&&!profileForm.controls.name.valid}" class="form-control" formControlName="name"
                           placeholder="{{api.strings?.portal.name}}"
                           type="text">
                    <div *ngIf="activeSubsection=='name'&&validationActive&&!profileForm.controls.name.disabled&&!profileForm.controls.name.valid"
                         class="input-error-message">
                      * {{ api.strings?.portal.field_required }}
                    </div>
                  </div>
                  <div class="col-lg-2 col-md-2 col-sm-2 col-12 text-right mobile-left">
                    <button (click)="update($event)" *ngIf="activeSubsection=='name'"
                            class="btn btn-default btn-sm">{{ api.strings?.portal.save }}
                    </button>
                    <a (click)="closeAccountSection($event,'name');" *ngIf="activeSubsection=='name'" class="close"
                       href="javascript:void(0);"><i aria-hidden="true"
                                                                        class="fa fa-times-circle-o"></i></a>
                  </div>
                </div>
              </li>
              <li (click)="profileForm.controls.email.setValue(api.user.email);activeSubsection='email';"
                  [ngClass]="{'active':activeSubsection=='email'}">
                <div class="row">
                  <div class="col-lg-4 col-md-4 col-sm-4 col-12">
                    <label class="main-label pl-xs-3">{{ api.strings?.portal.email }}:</label>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-6 col-12 mobile-margin-10 pointer">
                    <span *ngIf="activeSubsection!='email'" class="pl-xs-3">{{ api.user?.email }}</span>
                    <input *ngIf="activeSubsection=='email'" [ngClass]="{'input-error':validationActive&&!profileForm.controls.email.disabled&&!profileForm.controls.email.valid}" class="form-control" disabled
                           formControlName="email"
                           placeholder="{{api.strings?.portal.email}}" type="email">
                    <div *ngIf="activeSubsection=='email'&&validationActive&&!profileForm.controls.email.disabled&&!profileForm.controls.email.valid"
                         class="input-error-message">
                      *{{ api.strings?.portal.field_required }}
                    </div>
                  </div>
                  <div class="col-lg-2 col-md-2 col-sm-2 col-12 text-right mobile-left">
                    <!-- <button (click)="update($event)" class="btn btn-default btn-sm" *ngIf="activeSubsection=='email'">{{api.strings?.portal.save}}</button> -->
                    <a (click)="closeAccountSection($event,'email');" *ngIf="activeSubsection=='email'" class="close"
                       href="javascript:void(0);"><i aria-hidden="true"
                                                                         class="fa fa-times-circle-o"></i></a>
                  </div>
                </div>
              </li>
              <li (click)="profileForm.controls.phone.setValue(api.user.phone);activeSubsection='phone';"
                  [ngClass]="{'active':activeSubsection=='phone'}">
                <div class="row">
                  <div class="col-lg-4 col-md-4 col-12">
                    <label class="main-label">{{ api.strings?.portal.phone }}:</label>
                  </div>
                  <div class="col-lg-6 col-md-6 col-12 mobile-margin-10 pointer">
                    <span *ngIf="activeSubsection!='phone'" class="pl-xs-3">{{ getPhone() }}</span>
                    <input *ngIf="activeSubsection=='phone'" [ngClass]="{'input-error':validationActive&&!profileForm.controls.phone.disabled&&!profileForm.controls.phone.valid}" class="form-control" formControlName="phone"
                           mask="(000) 000-0000"
                           placeholder="{{api.strings?.portal.phone}}"
                           type="tel">
                    <div *ngIf="activeSubsection=='phone'&&validationActive&&!profileForm.controls.phone.disabled&&!profileForm.controls.phone.valid"
                         class="input-error-message">
                      *
                      {{ api.strings?.portal.field_required }} format: (000) 000-0000
                    </div>
                  </div>


                  <div class="col-lg-2 col-md-2 col-12 text-right mobile-left">
                    <button (click)="update($event)" *ngIf="activeSubsection=='phone'"
                            class="btn btn-default btn-sm">{{ api.strings?.portal.save }}
                    </button>
                    <a (click)="closeAccountSection($event,'phone');" *ngIf="activeSubsection=='phone'" class="close"
                       href="javascript:void(0);"><i aria-hidden="true"
                                                                         class="fa fa-times-circle-o"></i></a>
                  </div>
                </div>
              </li>
              <li (click)="profileForm.controls.password.setValue('');profileForm.controls.new_password.setValue('');activeSubsection='password';"
                  [ngClass]="{'active':activeSubsection=='password'}">
                <div class="row">
                  <div class="col-lg-4 col-md-4 col-sm-4 col-12">
                    <label class="main-label pl-xs-3">{{ api.strings?.portal.password }}:</label>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-6 col-12 mobile-margin-10 pointer">
                    <span *ngIf="activeSubsection!='password'" class="pl-xs-3">***************</span>
                    <div class="form-group">
                      <input (click)="stopEvent($event)" *ngIf="activeSubsection=='password'" [ngClass]="{'input-error':validationActive&&!profileForm.controls.password.disabled&&!profileForm.controls.password.valid}"
                             class="form-control" formControlName="password"
                             placeholder="{{api.strings?.portal.password}}"
                             type="password">
                      <div *ngIf="activeSubsection=='password'&&validationActive&&!profileForm.controls.password.disabled&&!profileForm.controls.password.valid"
                           class="input-error-message">
                        *
                        {{ api.strings?.portal.field_required }}
                      </div>
                    </div>
                    <div class="form-group">
                      <input (click)="stopEvent($event)" *ngIf="activeSubsection=='password'" [ngClass]="{'input-error':validationActive&&!profileForm.controls.new_password.disabled&&!profileForm.controls.new_password.valid}"
                             class="form-control" formControlName="new_password"
                             placeholder="{{api.strings?.portal.confirm_new_password}}"
                             type="password">
                      <div *ngIf="activeSubsection=='password'&&validationActive&&!profileForm.controls.new_password.disabled&&!profileForm.controls.new_password.valid"
                           class="input-error-message">
                        *
                        {{ api.strings?.portal.passwords_do_not_match }}
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-2 col-md-2 col-12 text-right mobile-left">
                    <button (click)="update($event)" *ngIf="activeSubsection=='password'"
                            class="btn btn-default btn-sm">{{ api.strings?.portal.save }}
                    </button>
                    <a (click)="closeAccountSection($event,'password');" *ngIf="activeSubsection=='password'" class="close"
                       href="javascript:void(0);"><i aria-hidden="true"
                                                                            class="fa fa-times-circle-o"></i></a>
                  </div>
                </div>
                <div class="col-lg-12 col-md-12 col-12 px-0">
                </div>
              </li>
            </ul>
          </form>
        </div>
        <div *ngIf="subscription" class="col-lg-12 col-md-12 col-12 animated fadeIn settings-card card-shadow">
          <div class="row ml-20">
            <div class="col-md-12 mt-10 mb-10">
              <h3 class="mt-10 bold">Current plan</h3>
            </div>
            <div class="col-md-3">
              <label class="main-label">{{ api.strings?.portal.member_since }}:</label>
            </div>
            <div class="col-md-9">
              {{ getDate(user?.signupDate * 1) }}
            </div>
            <div class="col-md-3">
              <label class="main-label">{{ api.strings?.portal.membership_status }}:</label>
            </div>
            <div class="col-md-9">
              {{ getMembershipLabel() }} <span *ngIf="subscription?.amountDue === 0 && doesIncludeLifetime(user.partnerId) "> Lifetime</span>
            </div>
            <ng-container *ngIf="user?.partnerId !== 'super'" class="row">
              <div *ngIf="api?.premium && subscription?.amountDue !==0" class="col-md-3">
                <label class="main-label">Renewal Information:</label>
              </div>
              <div *ngIf="api?.premium" class="col-md-9">

                <span *ngIf="!subscription?.paused && subscription?.amountDue !==0 && !subscription?.cancelAt">Your Annual {{ getMembershipLabel() }}
                  Membership will renew on {{ subscription?.renew }}
                  at ${{ subscription?.amountDue | number:'1.0-0' }}</span>

                <span *ngIf="subscription?.paused && subscription?.amountDue && !subscription?.cancelAt; else canceled">Your {{ getMembershipLabel() }}
                  Membership was paused for 6 months.
                <span *ngIf="subscription?.amountDue !==0">
                  Your next renewal date is {{ subscription?.paused }}
                  at at ${{ subscription?.amountDue | number:'1.0-0' }}
                </span>
              </span>
                <ng-template #canceled>
              <span *ngIf="subscription?.cancelAt">Your {{ getMembershipLabel() }} Membership was canceled. It will end on
                {{ subscription?.renew || subscription?.cancelAt }} <br>If you'd like to resubscribe please contact <a
                  href="mailto:support@dollarflightclub.com" id="my_custom_link">support</a>
              </span>

                </ng-template>
                <br>
                <a href="https://help.dollarflightclub.com/en/articles/2488214-what-dfc-plan-is-right-for-me"
                   target="_blank"> Billing FAQ</a>
              </div>
            </ng-container>
          </div>
          <!--CANCELLATION ACTIONS-->
          <div class="row">
            <div class="col-md-12 text-center my-4 mb-sm-0 mt-sm-3">
              <a *ngIf="api?.premium" [routerLink]="['/profile/update']"
                 class="btn btn-default" style="padding: 5px 30px;"><span *ngIf="subscription.trialActive">Update Plan</span><span
                *ngIf="!subscription.trialActive">Update / Pause Plan</span></a>
            </div>
          </div>
          <!--BILLING INFORMATION-->
          <div *ngIf="user?.partnerId !== 'super'">
            <div *ngIf="api?.premium" class="row mt-20 ml-20">
              <div class="col-md-12 mt-10 mb-10">
                <h3 class="mt-20 bold">Billing Information</h3>
              </div>
              <div class="col-md-3">
                <label class="main-label">Payment Method:</label>
              </div>
              <div class="col-md-9">
              <span>
                {{ subscription?.paymentMethod?.brand }}
              </span>
                <span class="ml-10">(ending in) {{ subscription?.paymentMethod?.last4 }} </span>
                <a (click)="openModal(true)" class="ml-10" href="JavaScript:Void(0);"> - Update payment info</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--UPDATE/CANCEL-->
      <div *ngIf="activeSection=='update'" class="w-100">
        <app-cancel-modal></app-cancel-modal>
      </div>
    </div>
  </div>
</div>
<static-footer></static-footer>
